import type { MediaSettingsFileUpdateDto } from './models';
import type { SupportVideoType } from './support-video-type.enum';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { MediaFileModelDto } from '../media-files/models';

@Injectable({
  providedIn: 'root',
})
export class MediaSettingsService {
  apiName = 'Default';
  

  getMedia = (name: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MediaFileModelDto>({
      method: 'GET',
      url: `/api/app/media-settings/get-media/${name}`,
    },
    { apiName: this.apiName,...config });
  

  getSupportVideoThumbnailsByVideoType = (videoType: SupportVideoType, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MediaFileModelDto[]>({
      method: 'GET',
      url: `/api/app/media-settings/get-support-video-thumbnails/${videoType}`,
    },
    { apiName: this.apiName,...config });
  

  getSupportVideosByVideoType = (videoType: SupportVideoType, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MediaFileModelDto[]>({
      method: 'GET',
      url: `/api/app/media-settings/get-support-videos/${videoType}`,
    },
    { apiName: this.apiName,...config });
  

  updateMedia = (input: MediaSettingsFileUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/media-settings/upload-media',
      body: input.file.file,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
